<template>
    <div class="card" style="margin-top:0;">
        <div class="card-header card-header-icon card-header-rose">
            <div class="card-icon">
                <i class="material-icons">notes</i>
            </div>
            <h3 class="card-title">N° VIAJES Y BIOMASA (TON) POR WELLBOAT</h3>
        </div>
        <div class="card-body">
            <v-row class="mx-0">
                <v-col cols="3">
                    <v-select prepend-icon="mdi-navigation" v-model="periodo" :items="periodos" item-text="anio"
                        item-value="id" label="Seleccione Año" color="blue darken-3" :reduce="item => item.id"></v-select>
                </v-col>
                <v-col cols="3">
                    <v-select prepend-icon="mdi-navigation" v-model="seleccion_meses" :items="meses" item-text="mes"
                        item-value="id" label="Seleccione Mes" color="blue darken-3" multiple
                        :reduce="item => item.id"></v-select>
                </v-col>
                <v-col cols="2">
                    <v-select prepend-icon="mdi-navigation" v-model="faena" :items="faenas" item-text="nombre"
                        item-value="id" label="Tipo de Faena" color="blue darken-3"></v-select>
                </v-col>
                <v-col class="text-right" cols="4">
                    <v-btn class="mx-2" dark color="blue darken-1" @click="generar_reporte"><i
                            class="material-icons">cached</i>
                        Generar</v-btn>
                    <v-btn class="mx-2" dark color="green" v-show="url" @click="descargar_excel"><i
                            class="material-icons">text_snippet</i> Excel</v-btn>
                    <!-- <v-btn class="mx-2" dark @click="downloadPDF" color="#C62828"> <i class="material-icons">picture_as_pdf</i> PDF</v-btn> -->
                </v-col>
            </v-row>

            <spinner v-if="spinner"></spinner>

            <v-simple-table v-if="data.length > 0">
                <thead>
                    <tr>
                        <th class="text-center">WELLBOAT</th>
                        <th class="text-center">N° VIAJES</th>
                        <th class="text-center">TOTAL CARGA BIOMASA (Ton)</th>
                    </tr>
                </thead>
                <tbody class="bg-reporte-factura">
                    <tr v-for="item in data" :key="item.id">
                        <td>{{ item.wellboat }}</td>
                        <td class="text-right">{{ item.nro_viajes }}</td>
                        <td class="text-right">{{ item.biomasa | formatear_miles }}</td>
                    </tr>
                </tbody>
            </v-simple-table>


        </div>


    </div>
</template>
<style scoped>
.table-wellboat {
    font-size: 16pt;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
}

.card {
    margin-top: 0;
}

.card .card-header-rose .card-icon {
    background: #263238;
    box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14),
        0 7px 10px -5px rgba(32, 30, 31, 0.822);
}
</style>
<script>
import { mapState } from "vuex";

export default {
    data: () => ({
        spinner: false,
        meses: [
            { id: 1, mes: 'Enero' },
            { id: 2, mes: 'Febrero' },
            { id: 3, mes: 'Marzo' },
            { id: 4, mes: 'Abril' },
            { id: 5, mes: 'Mayo' },
            { id: 6, mes: 'Junio' },
            { id: 7, mes: 'Julio' },
            { id: 8, mes: 'Agosto' },
            { id: 9, mes: 'Septiembre' },
            { id: 10, mes: 'Octubre' },
            { id: 11, mes: 'Noviembre' },
            { id: 12, mes: 'Diciembre' },
        ],
        periodos: [],
        wellboats: [],
        faenas: [
            { id: 0, nombre: 'Todos' },
            { id: 1, nombre: 'Cosecha' },
            { id: 3, nombre: 'Smolt' },
            { id: 4, nombre: 'Mortalidad' },
            { id: 5, nombre: 'Baño' },
            { id: 6, nombre: 'Vacuna' },
        ],
        periodo: null,
        periodos_data: [],
        seleccion_meses: [],
        faena: 0,
        data: [],
        url: null,
        filitas: '',
    }),
    async mounted() {
        await this.cargar_periodos()
    },
    methods: {
        async cargar_periodos() {
            let url = `${this.base_url}reportes/sw/cargar_periodos`;
            await this.axios
                .get(url, this.headers)
                .then((response) => {
                    this.periodos = response.data.periodos;
                    //this.periodo = this.periodos[0]
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        async generar_reporte() {
            let url = `${this.base_url}reportes/sw/reporte_nro_viajes_biomasa_wellboat`;
            let body = {
                periodo: this.periodo,
                faena: this.faena,
                meses_ids: this.seleccion_meses.sort((a,b)=> a-b)
            }
            await this.axios
                .post(url, body, this.headers)
                .then((response) => {
                    console.log('response', response.data)
                    this.data = response.data.data
                    this.url = response.data.url
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        descargar_excel() {
            window.open(this.url);
        }
    },
    computed: {
        ...mapState(["storage_url", "loading", "base_url", "headers", "user"]),
    },
    components: {},
};
</script>
<style>
th {
    background-color: #355f94;
    color: #fff !important;
    border: 1px solid #000301;
}

tbody.bg-reporte-factura tr:last-child{
          background: #ced4da;
          font-weight: 500;
      }

.periodo {
    background: #ced4da;
    font-weight: 500;
}

.bg-yellow {
    background-color: #fff2b2;
    font-weight: 500;
}
</style>
    